import React from 'react'

class GlobalNavAccordionButton extends React.Component {
  constructor(props) {
    super(props)
    this.state = { isOpen: props.initialOpenState}
  }
  
  handleClick = () => {
    this.setState(prevState => ({isOpen: !prevState.isOpen}))
  }

  render() {

    const { isOpen } = this.state;

    return (
      <span
        className={`p-headNav__parentIcon ${isOpen ? 'is-active' : ''}`}
        onClick={() => this.handleClick()}
      />
    )
  }
} 

export default GlobalNavAccordionButton