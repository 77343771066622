const scrollAddClass = () => {

  typeof window !== "undefined" && window.addEventListener('scroll', () => {
    let scrollFlag
    let scrollTop
    const userAgent = window.navigator.userAgent.toLowerCase()
    if (userAgent.indexOf('trident') !== -1) {
      scrollTop = document.documentElement.scrollTop
    } else {
      scrollTop = document.scrollingElement.scrollTop
    }
    const scrollElements = document.getElementsByClassName('js-scrollEvent')
    
    if (!scrollFlag && 50 < scrollTop ) {
      Array.prototype.forEach.call(scrollElements, (element) => {
        element.classList.add('isScroll')
        element.classList.remove('isReverse')
      })
      scrollFlag = true
    }
    if( scrollTop < 50) {
      Array.prototype.forEach.call(scrollElements, (element) => {
        element.classList.remove('isScroll')
        element.classList.add('isReverse')
      })
      scrollFlag = false
    }
  }, false)
}

export default scrollAddClass
