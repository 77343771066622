import React from 'react'
import { Link } from 'gatsby'
import logo from '../img/logo.svg'

const toTopsmoothScroll = (event) => {
  event.preventDefault()
  window.scroll({
    top: 0,
    behavior: "smooth"
    });
}

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="l-footer">
        <div className="p-spFootBtns">
          <Link to="/contact" className="p-spFootBtns__button--contact">
            <img
              src="/img/common/icon_contact.svg"
              alt="お問い合わせページリンク"
              className="p-spFootBtns__contactIcon" />
          </Link>
          <a href="#___gatsby" className="p-spFootBtns__button--toTop" onClick={toTopsmoothScroll} title="このページの最初へ">
            <span className="u-displayNone">このページの先頭へ移動</span>
          </a>
        </div>
        <div className="p-footContact">
          <div className="l-container--wide">
            <p className="u-font--18 u-font--sp14" data-spacing="mb6">
              案件のご依頼やご相談など、<br className="u-mqShow--smBlock" />お気軽にお問い合わせからご相談ください。
            </p>
            <div>
              <Link to="/contact" className="c-button--skewOrange p-footContact__button">
                <span className="c-icon--mailWhite c-button__mail p-footContact__mailIcon" />
                <span className="p-footContact__buttonText">アーバへのお問い合わせ<span className="u-mqHidden--sm">はこちら</span></span>
                <span className="c-icon--arrowWhite c-button__arrow" />
              </Link>
            </div>
          </div>
        </div>
        <div className="l-container--wide">
          <div className="p-footBottom">
            <ul className="p-footBottom__list">
              <li className="p-footBottom__item">
                <Link to="/" className="p-footBottom__link">ホーム</Link>
              </li>
              <li className="p-footBottom__item">
                <Link to="/service" className="p-footBottom__link">サービス</Link>
              </li>
              <li className="p-footBottom__item">
                <Link to="/work" className="p-footBottom__link">開発事例</Link>
              </li>
              <li className="p-footBottom__item">
                <Link to="/company" className="p-footBottom__link">会社概要</Link>
              </li>
              <li className="p-footBottom__item">
                <Link to="/blog" className="p-footBottom__link">ブログ</Link>
              </li>
              <li className="p-footBottom__item">
                <Link to="/recruit" className="p-footBottom__link">採用情報</Link>
              </li>
              <li className="p-footBottom__item">
                <Link to="/faq" className="p-footBottom__link">よくある質問</Link>
              </li>
              <li className="p-footBottom__item">
                <Link to="/privacypolicy" className="p-footBottom__link">プライバシーポリシー</Link>
              </li>
              <li className="p-footBottom__item">
                <Link to="/lp" className="p-footBottom__link">with コロナのシステム開発</Link>
              </li>
              <li className="p-footBottom__item">
                <Link to="/contact" className="p-footBottom__link">お問い合わせ</Link>
              </li>
            </ul>
            <div className="p-footBottom__copy">
              <figure className="p-footBottom__logo">
                <Link to="/" className="p-footBottom__link">
                  <img src={logo} alt="株式会社アーバ" />
                </Link>
              </figure>
              <p className="p-footBottom__copyText">
                <span className="u-mqHidden--sm">Copyright</span>
                &copy; ARBOR Co., Ltd 
                <span className="u-mqHidden--sm">All rights reserved</span>
              </p>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
