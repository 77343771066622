import React from 'react'
import { Link } from 'gatsby'
import logo from '../img/logo.svg'
import PropTypes from 'prop-types'


// TODO: 採用ページオープン時にコメントアウトを解除する


const propTypes = {
  dataHamburger: PropTypes.func,
}

class GlobalNavigation extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  clickButton() {
    return this.props.dataHamburger();
  }

  render() {

    const { NavClass } = this.props
    const str = ""

    return (
      <nav className={NavClass}>
        <Link
          to="/"
          onClick={() => { this.clickButton() }}
        >
          <figure className="p-headNav__logo">
            <img className="p-headNav__img" src={logo} alt="アーバロゴマーク" />
          </figure>
        </Link>
        <ul className="p-headNav__list">
          <li className="p-headNav__item u-mqHidden--sm">
            <Link
              to="/"
              className="p-headNav__link"
              onClick={() => { this.clickButton() }}
            >
              ホーム
            </Link>
          </li>
          <li className="p-headNav__item">
            <Link
              to="/work"
              className="p-headNav__link"
              onClick={() => { this.clickButton() }}
            >
              開発事例
            </Link>
          </li>
          <li className="p-headNav__item">
            <Link
              to="/service"
              className="p-headNav__link"
              onClick={() => { this.clickButton() }}
            >
              サービス
            </Link>
          </li>
          <li className="p-headNav__item">
            <Link
              to="/faq"
              className="p-headNav__link"
              onClick={() => { this.clickButton() }}
            >
              よくある質問
            </Link>
          </li>
          <li className="p-headNav__item">
            <Link
              to="/blog"
              className="p-headNav__link"
              onClick={() => { this.clickButton() }}
            >
              ブログ 
            </Link>
          </li>
          {/* <li className="p-headNav__item--hasChild">
            <Link
              to="/recruit"
              className="p-headNav__link"
              onClick={() => { this.clickButton() }}
            >
              採用情報
            </Link>
            <GlobalNavAccordionButton />
            <ul className="p-headNav__child">
              <li className="p-headNav__childItem">
                <Link
                  to="/recruit/career"
                  className="p-headNav__childLink"
                  onClick={() => { this.clickButton() }}
                >
                  中途採用
                </Link>
              </li>
              <li className="p-headNav__childItem">
                <Link
                  to="/recruit/partner"
                  className="p-headNav__childLink"
                  onClick={() => { this.clickButton() }}
                >
                  業務委託
                </Link>
              </li>
              <li className="p-headNav__childItem">
                <Link
                  to="/recruit/entry"
                  className="p-headNav__childLink"
                  onClick={() => { this.clickButton() }}
                >
                  エントリー
                </Link>
              </li>
            </ul>
          </li>*/}
          <li className="p-headNav__item">
            <Link
              to="/company"
              className="p-headNav__link"
              onClick={() => { this.clickButton() }}
            >
              会社概要 
            </Link>
          </li>
          <li className="p-headNav__item u-mqShow--smBlock">
            <Link
              to="/privacypolicy"
              className="p-headNav__link"
              onClick={() => { this.clickButton() }}
            >
              プライバシーポリシー 
            </Link>
          </li>
          <li className="p-headNav__item">
            <Link
              to="/contact"
              className="p-headNav__link--contact c-button--skewOrange"
              onClick={() => { this.clickButton() }}
            >
              お問い合わせ
            </Link>
          </li>
        </ul>
      </nav>
    )
  }
} 


export default GlobalNavigation
